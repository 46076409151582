import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { breakpoints, colors, transitions } from '../theme'
import slickStyles, { slickTheme } from '../assets/slick-styles'
import TileNav from './TileNav'
import Arrow from '../assets/arrow.svg'

class TileList extends React.Component {
  state = {
    activeItem: null,
    nav: [],
    oneSlide: this.props.settings.slidesToShow === 1,
  }

  componentDidMount() {
    const nav = []
    this.props.tiles.forEach(tile => {
      if (tile.chip) {
        nav.push(tile.chip)
      }
    })
    this.setState({
      nav: nav,
      activeItem: nav[0],
    })
  }

  navigate = item => {
    const slider = this.slider
    this.setState({ activeItem: item })
    const index = this.state.nav.indexOf(item)
    slider.slickGoTo(index)
  }

  afterChange = index => {
    this.setState({ activeItem: this.state.nav[index] })
  }

  render() {
    const {
      settings,
      arrowYOffset,
      centered = false,
      title,
      subTitle,
      tiles,
      renderTile,
      ...props
    } = this.props

    const { nav, activeItem, oneSlide } = this.state

    const centeredClass = centered ? 'centered' : ''
    const oneSlideClass = oneSlide ? 'one-slide' : ''

    return (
      <StyledTileList
        {...props}
        arrowYOffset={arrowYOffset}
        className={`${centeredClass} ${oneSlideClass}`}
      >
        {title && (
          <div className="title">
            {title && <h3>{title}</h3>}
            {subTitle && <p className="subtitle">{subTitle}</p>}
          </div>
        )}
        <Slider
          afterChange={this.afterChange}
          ref={slider => (this.slider = slider)}
          {...settings}
        >
          {tiles.map(tile =>
            React.cloneElement(renderTile, { key: tile.id, ...tile }),
          )}
        </Slider>
        {/*{nav.length > 0 && (
          <TileNav
            navItems={nav}
            activeItem={activeItem}
            clickOnItem={this.navigate}
          />
        )}*/}
      </StyledTileList>
    )
  }
}

const StyledTileList = styled.section`
  ${slickStyles};
  ${slickTheme};

  .slick-track {
    margin-left: 17%;
  }

  .container {
    overflow: hidden;
  }

  .title {
    margin-left: 17%;
    margin-bottom: 10px;
  }

  &.one-slide {
    .title {
      margin-left: 0;
    }

    .slick-track {
      margin-left: 0;
    }

    .slick-slide {
      &:not(.slick-active) {
        opacity: 0;
      }
    }
  }

  &.centered {
    .title {
      margin-left: 0;
      text-align: center;
    }
    .slick-track {
      margin-left: 0;

      .slick-slide {
        > div:first-child {
          margin-bottom: 60px;
        }
      }
    }
  }

  .slick-list {
    margin-left: -15px;
    margin-right: -15px;
  }

  .slick-slide {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    padding: 0 8.5px;

    &:not(.slick-active) {
      transition-timing-function: ease-out;
      transition-delay: 300ms;
      transition-duration: 200ms;
    }
    &.slick-active {
      opacity: 1;
      transition-duration: 500ms;
    }
  }

  .slick-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    height: 76px;
    width: 76px;
    background: #f5f5f5;
    border-radius: 50px;
    transition: ${transitions.all};
    opacity: 0;

    &:hover {
      background: #fff;
    }

    &:before {
      color: #000;
      content: ' ';
      opacity: 1;
      font-size: 30px;
      transition: opacity ease 150ms;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(${Arrow});
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover:before {
      opacity: 0.75;
    }
  }

  .slick-slider:hover {
    .slick-arrow {
      opacity: 1;
    }
  }

  .slick-next {
    right: calc(-6vw + 12px);
    padding: 0 20px;

    &:before {
      transform: rotate(180deg);
    }
  }

  .slick-prev {
    left: calc(-6vw + 12px);
    /* -1px in Z axis is a Safari/Webkit bug: https://stackoverflow.com/questions/18146511/bug-in-css3-rotatey-transition-on-safari */

    &:before {
      display: block;
    }
  }

  h3 {
    font-size: 47px;
    font-weight: 500;
    color: ${colors.brand.normal};
  }

  .subtitle {
    font-size: 21px;
    margin-bottom: 72px;
  }

  @media (max-height: 840px) and (min-width: ${breakpoints.desktop}px) {
    .slick-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      height: 46px;
      width: 46px;

      &:before {
        background-size: 25px 25px;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    h3 {
      margin-left: auto;
      margin-right: auto;
    }
    .slick-list {
      margin: 0;
    }
  }
  @media (max-width: ${breakpoints.desktop}px) {
    .slick-list {
      margin-left: -10px;
      margin-right: -10px;
    }
    .slick-track,
    .title {
      margin-left: 0;
    }

    .subtitle {
      font-size: 17px;
      margin-bottom: 36px;
    }

    .slick-slide {
      padding: 0 10px;
      &:not(.slick-active) {
        opacity: 1;
      }
    }

    h3 {
      font-size: 27px;
      margin-left: 0;
    }
    .slick-arrow {
      display: block;
      top: calc(300px / 2);
      height: 41px;
      width: 41px;
      opacity: 1;

      &:before {
        background-size: 20px 20px;
      }
    }
    .slick-next {
      right: 12px;
      padding: 0 10px;
    }

    .slick-prev {
      left: 12px;
    }
  }
`

export default TileList
export { StyledTileList }
