/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React, { Component } from 'react'
import illuBanquet1 from '../assets/e-banquet-illu-1.png'
import illuBanquet2 from '../assets/e-banquet-illu-2.png'
import illuBanquet3 from '../assets/e-banquet-illu-3.png'
import illuBanquet4 from '../assets/e-banquet-illu-4.png'
import illuFeedback from '../assets/illuFeedback.jpg'
import ArtisanTile from '../components/ArtisanTile'
import Banner from '../components/banners/Banner'
import TileList from '../components/TileList'
import StyledPage from '../style/traiteurs-evenementiel'
import { breakpoints } from '../theme'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// https://react-slick.neostack.com/docs/api#settings
const settings = {
  infinite: false,
  speed: 500,
  lazyLoad: 'progressive',
  swipeToSlide: true,
  draggable: false,
  mobileFirst: true,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: breakpoints.desktop + 1,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: breakpoints.tablet + 1,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: breakpoints.mobile + 1,
      settings: { slidesToShow: 2 },
    },
  ],
}

class TraiteursEvenementielPage extends Component {
  render() {
    const page = this.props.data.page
    return (
      <StyledPage>
        <Navbar />
        <div className="page _traiteurs-evenementiel">
          <div className="traiteurs--hero-block">
            <h2>Traiteurs Evénementiel</h2>
            <p>Donnez du goût à vos événements d'entreprise</p>
            <a href="/traiteurs-pas-cher">Découvrez nos traiteurs</a>
          </div>
          <section className="evenements">
            <div className="container">
              <h2>Un banquet pour tous vos événements d’entreprise</h2>
              <div className="evenements--list">
                <div className={`evenement-illu _n-1`}>
                  <div className="img" />
                  <div className="sub">
                    <h2>Petit-Déjeuner</h2>
                    <p>A partir de 5 euros par personne</p>
                  </div>
                </div>
                <div className={`evenement-illu _n-2`}>
                  <div className="img" />
                  <div className="sub">
                    <h2>Déjeuner</h2>
                    <p>A partir de 7 euros par personne</p>
                  </div>
                </div>
                <div className={`evenement-illu _n-3`}>
                  <div className="img" />
                  <div className="sub">
                    <h2>Goûter</h2>
                    <p>A partir de 5 euros par personne</p>
                  </div>
                </div>
                <div className={`evenement-illu _n-4`}>
                  <div className="img" />
                  <div className="sub">
                    <h2>Cocktail</h2>
                    <p>A partir de 7 euros par personne</p>
                  </div>
                </div>
                <div className={`evenement-illu _n-5`}>
                  <div className="img" />
                  <div className="sub">
                    <h2>Dîner</h2>
                    <p>A partir de 10 euros par personne</p>
                  </div>
                </div>
                <div className={`evenement-illu _n-6`}>
                  <div className="img" />
                  <div className="sub">
                    <h2>Soirée événementielle</h2>
                    <p>A partir de 15 euros par personne</p>
                  </div>
                </div>
              </div>

              <div className="feedback">
                <img
                  src={illuFeedback}
                  alt="feedback illustration"
                  className="--illu"
                  loading="lazy"
                />
                <div className="--text">
                  <p className="--main">
                    Nous avons organisé plusieurs petits déjeuners avec
                    Monbanquet sur Paris! Et nous avons toujours été ravies de
                    leur prestation : une écoute de nos besoins, une réponse
                    adaptée, un budget respecté, un timing parfait et surtout
                    des produits simples, variés et de qualité. Tout est facile
                    avec Monbanquet !
                  </p>
                  <p className="--sub-name">Béatrice CAYOL et Marine PERLI</p>
                  <p className="--sub-text">
                    Associées de{' '}
                    <a
                      href="agencebam.fr"
                      target="_blank"
                      rel="noopener nofollower"
                    >
                      Agencebam.fr
                    </a>
                  </p>
                  <p className="--sub-combo">
                    Béatrice CAYOL et Marine PERLI Associées de{' '}
                    <a
                      href="agencebam.fr"
                      target="_blank"
                      rel="noopener nofollower"
                    >
                      Agencebam.fr
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="banquet">
            <div className="container_shrink">
              <div className="row">
                <div className="data-block _l">
                  <h3>
                    Focus sur la qualité de l’expérience de vos événements
                  </h3>
                  <p>
                    La qualité et l'expérience sont nos fer de lance. Combinez
                    les deux et vous avez la description d’un événements
                    Monbanquet ! C'est l'expérience traiteur événementiel.
                  </p>
                  <p>
                    Nous travaillons uniquement avec des artisans doté d’un réel
                    savoir-faire utilisant des produits de qualité. Pour ce qui
                    est de l'expérience, quoi de mieux que des produits arrivant
                    en temps et en heure et servi avec le sourire.
                  </p>
                </div>
                <div className="img-block _r">
                  <img
                    src={illuBanquet1}
                    alt="Banquet illustration"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="row">
                <div className="img-block _l">
                  <img
                    src={illuBanquet2}
                    alt="Banquet illustration"
                    loading="lazy"
                  />
                </div>
                <div className="data-block _r">
                  <h3>5000 événements et toutes nos dents</h3>
                  <p>
                    Profitez de notre expérience pour tout type événements.
                    Notre métier est de créer des moments de convivialité et ça
                    commence dès la commande de votre banquet. Nous avons tous
                    les ingrédients pour réussir un événement sans contrainte !
                  </p>
                  <p>
                    Votre banquet est livré sur plateaux une heure avant votre
                    événement : rien à réchauffer, rien à découper, juste à
                    déguster ! Nos maîtres d'hôtel vous accompagnent sur vos
                    plus beaux événements.
                  </p>
                </div>
                <div className="img-block _duple">
                  <img
                    src={illuBanquet2}
                    alt="Banquet illustration"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="row">
                <div className="data-block _l">
                  <h3>La crème du savoir-faire local</h3>
                  <p>
                    Nous avons choisi de travailler uniquement avec des artisans
                    locaux au savoir‑faire réputé dans leur quartier ou leur
                    ville partout en France.
                  </p>
                  <p>
                    Nous les aidons à développer des menus simples avec du goût
                    et sans fioriture adaptés aux réceptions, pauses et moments
                    conviviaux entre personnes qui travaillent ensemble. En
                    valorisant leur savoir‑faire, nous proposons une nouvelle
                    offre traiteur de meilleure qualité et à prix juste.
                  </p>
                </div>
                <div className="img-block _r">
                  <img
                    src={illuBanquet3}
                    alt="Banquet illustration"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="row">
                <div className="img-block _l">
                  <img
                    src={illuBanquet4}
                    alt="Banquet illustration"
                    loading="lazy"
                  />
                </div>
                <div className="data-block _r">
                  <h3>On s’occupe de tout </h3>
                  <p>
                    Lorsque vous passez commande, Louise, Danielle ou
                    Jean-Charles préparent votre événement en s’adaptant à vos
                    contraintes de temps, de budget et… de goût.
                  </p>
                  <p>
                    Paul et Martin auront avant sélectionné les meilleurs
                    artisans locaux. La conférence est au 10ème étage, le
                    parking est inaccessible, vous n'avez pas le code
                    d'entrée... C’est compliqué pour vous : c’est notre
                    quotidien ! La solution parfaite pour vos événements
                    professionnels.
                  </p>
                </div>
                <div className="img-block _duple">
                  <img
                    src={illuBanquet4}
                    alt="Banquet illustration"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="artisans">
            <div className="container">
              <h2>
                Nos artisans sont pâtissiers, brasseurs, fleuristes,
                fromagers...
              </h2>

              <TileList
                settings={settings}
                title={page.artisanList.title}
                tiles={page.artisanList.artisans}
                renderTile={<ArtisanTile />}
                className="artisan-tile-list"
              />

              <Banner {...page.banner2} />
            </div>
          </section>
        </div>
        <Footer />
      </StyledPage>
    )
  }
}

export const query = graphql`
  {
    page: contentfulHomePage(fixedId: { eq: "homepage-1" }) {
      banner2 {
        ...BannerFragment
      }
      artisanList {
        title
        artisans {
          ...ArtisanFragment
        }
      }
    }
  }
`

export default TraiteursEvenementielPage
