import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../theme'

const TileNav = ({ navItems, activeItem, clickOnItem, ...props }) => {
  const handleClick = item => {
    if (
      navItems.indexOf(activeItem) === navItems.length - 2 &&
      navItems.indexOf(item) === navItems.length - 1
    ) {
      return
    }
    clickOnItem(item)
  }

  return (
    <StyledTileNav {...props}>
      {navItems.map((item, index) => (
        <div
          className={`nav-item ${navItems.indexOf(activeItem) === index &&
            'active'}`}
          onClick={() => handleClick(item)}
          onKeyPress={() => handleClick(item)}
          role="button"
          tabIndex={index}
          key={index}
        >
          {item}
        </div>
      ))}
    </StyledTileNav>
  )
}

const StyledTileNav = styled.section`
  margin: 65px auto 0;
  display: flex;
  justify-content: center;
  text-align: center;

  .nav-item {
    width: 120px;
    color: #000000;
    opacity: 0.33;
    border-bottom: 9px solid ${colors.brand.inverted};
    margin-right: 19px;
    outline: none;
    cursor: pointer;
    transition: all 250ms ease-in-out;

    &:hover,
    &.active {
      font-family: Centra Bold;
      opacity: 1;
      color: ${colors.brand.normal};
      border-color: ${colors.brand.normal};
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    display: none;
  }
`

export default TileNav
export { StyledTileNav }
