/* eslint-disable */

import styled from 'styled-components'
import bgHero from '../assets/paris-15-hero.png'
import illu1 from '../assets/e-illu-1.png'
import illu2 from '../assets/e-illu-2.png'
import illu3 from '../assets/e-illu-3.png'
import illu4 from '../assets/e-illu-4.png'
import illu5 from '../assets/e-illu-5.png'
import illu6 from '../assets/e-illu-6.png'
import icoBrace from '../assets/icons/brace.svg'
import icoBraceF from '../assets/icons/braceF.svg'

import icoStars from '../assets/stars.svg'
import icoTada from '../assets/tada.svg'
import icoOk from '../assets/ok.svg'
import icoWD from '../assets/wd.svg'

export default styled.section`
  .page._traiteurs-evenementiel {
    margin: 0 auto;
    padding: 0;
    margin-top: 80px;
    @media (max-width: 768px) {
      margin-top: 100px;
    } 
  }
  .traiteurs--hero-block {
    background-image: url(${bgHero});
    background-size: auto 100%;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-color: #f6f6f7;
    text-align: left;
    padding: 70px 25vw;
     @media(max-width: 768px){
      padding: 10px 25px 30px;
      background-image: none;
    }
    h2 {
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      font-size: 40px;
      color: 333;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    p {
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      font-size: 24px;
      color: #333;
      margin-bottom: 30px;
      @media(max-width: 768px){
        font-size: 16px;
      } 
    }
    a {
      padding: 10px 20px;
      width: 250px;
      text-align: center;
      color: #fff;
      background-color: #FC4C4C;
      border: solid 1px rgba(0, 0, 0, 0.1);
      text-decoration: none;
      border-radius: 25px;
      margin: 20px 0;
      display: inline-block;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      &:hover {
        opacity: .8;
      }
      @media(max-width: 768px){
        display: none;
      }
    }
  }
  
  .container {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1270px) {
      padding: 0 15px;
    }
    &_shrink {
      max-width: 1070px;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 1070px) {
      padding: 0 15px;
    }
    }
  }
  section h2 {
    line-height: 1.2;
    font-size: 40px;
    text-align: center;
    font-weight: 500;
    margin: 20px 0 30px;
    @media (max-width: 768px) {
      font-size: 20px;
      text-align: left;
      margin: 0;
    }
  }
  section.evenements {
    padding: 80px 0;
    @media (max-width: 768px) {
      padding: 40px 0;
    }
    p.sectionSub {
      text-align: center;
      width: 80%;
      margin: 0 auto;
      font-weight: 300;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
      color: #000000;
    } 
    .evenements--list {
      overflow: hidden;
      margin: 20px 0;
      .evenement-illu {
        width: calc(33.33% - 20px);
        margin: 10px;
        float: left;
        transition: transform .3s ease;
        .sub {
          text-align: left;
          margin-bottom: 10px;
          h2 {
            margin: 0;
            line-height: 2;
            font-size: 16px;
            color: #666666;
            text-align: left;
            font-weight: 600;
            @media (max-width: 768px) {
              line-height: 1.2;
            }
          }
          p {
            margin: 0;
            line-height: 1.4;
            color: #B3B3B3;
            font-size: 14px;
            text-align: left;
            @media (max-width: 768px) {
              font-size: 10px;
            }
          }
        }
        .img {
            width: 100%;
            background-size: cover;
            background-position: center;
            border-radius: 2px;
            &::after {
              content: '';
              display: block;
              padding-bottom: 65%;
            }
        }
        @media (max-width: 580px){
          width: calc(50% - 20px);
        }
        &:hover {
          transform: scale(1.05);
        }
        &._n-1 .img{  background-image: url(${illu1}) }
        &._n-2 .img{  background-image: url(${illu2}) }
        &._n-3 .img{  background-image: url(${illu3}) }
        &._n-4 .img{  background-image: url(${illu4}) }
        &._n-5 .img{  background-image: url(${illu5}) }
        &._n-6 .img{  background-image: url(${illu6}) }
      }
    }
  }
  section.banquet {
    background: #F6F6F7;
    padding: 80px 0;
    @media (max-width: 768px){
      padding: 40px 0; 
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 580px){
        display: block;
        overflow: hidden;
      }
    }
    .data-block, .img-block {
      width: calc(50% - 30px);
      flex: calc(50% - 30px);
      margin: 40px 30px;
      @media (max-width: 580px){
        float: left;
        width: 100%;
        margin: 10px 0;
        img {
          margin: 0 auto;
        }
      }
    }
    .data-block {
      h3 {
      font-weight: 700;
      line-height: 32px;
      font-size: 24px;
      }
      p {
        font-weight: 300;
        line-height: 24px;
        font-size: 16px;
        margin: 15px 0;
      }
      &._l {
        padding-right: 50px;      
      }
      &._r {
        padding-left: 50px;      
      }
       @media (max-width: 580px){ 
        &._l {
          padding-right: 0;      
        }
        &._r {
          padding-left: 0;      
        }
       }
    }
    .img-block {
      position: relative;
      @media (max-width: 768px){ 
        margin: 20px 0;
        text-align: center;
      }
      img {
        width: 100%;
        height: auto;
      }
      &._r {
        .ico {
          left: -30px;
        }
      }
      &._l {
        .ico {
          right: -30px;
        } 
      }
      &._duple {display: none}
      @media (max-width: 768px){ 
        &._l {
          display: none;
        }
        &._duple {
          display: block;
        }
      }
    }
  }
    section.artisans {
      padding: 80px 0;
      p.subText {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        font-weight: 300;
        line-height: 24px;
        font-size: 16px;
        text-align: center;
      } 
      ._outer {
        margin: 40px 0 20px;
      }
      .slick-slide > div {
        padding: 20px;
       }
      .slider--outer div {
          position: relative;
    
          .text {
            position: absolute;
            bottom: 25px;
            left: 25px;
            h2 {
              color: #fff;
              text-align: left;
              font-size: 18px;
              font-weight: 700;
              line-height: 1.2;
              margin: 0;
            }
            p {
              text-align: left;
              width: 100%;
              color: #fff;
              font-size: 16px;
              font-weight: 400;
            }
          } 
         img {
          width: 100%;
          height: auto;
         }
        }
      }
      ._w3 {
        width: calc(25% - 40px);
        margin: 20px;
      }     
      .note {
        background-color: #F6F6F6;
        padding: 20px;
        width: calc(100% - 40px);
        margin: 0 auto;
        h3 {
          font-size: 18px;
          color: #666666;
          font-weight: 500;
          margin: 0;
          line-height: 2;
        }
        p {
          text-align: left;
          max-width; 100%;
          line-height: normal;
          font-size: 14px;
          color: #666666;
        }
      }
      @media (max-width: 580px){
        ._w12, ._w4, ._w6, ._w8 {
           width: 100%;
           margin-bottom: 10px;
        } 
        ._mt {
          margin-top: -10px;
        }
      }
  }
  .feedback {
    width: calc(100% - 20px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    @media (max-width: 768px){ 
      display: block;
      position: relative;
      margin-bottom: 70px;
    }
    .--illu {
      flex-basis: 30%;
      max-width: 30%;
      height: auto;
      @media (max-width: 768px){ 
        max-width: 95%;
        margin: 0 2.5%;
      }
    }
    .--text {
      flex-basis: 70%;
      max-width: 70%;
      padding: 71px 60px;
      position: relative;
      background-color: #f6f6f7;
      @media (max-width: 768px){ 
        position: absolute;
        top: 60%;
        left: -1%;
        max-width: 102%;
        padding: 10px 20px;
      }
      &:before {
        content: '';
        background-image: url(${icoBrace});
        background-size: cover;
        background-position: center;
        display: block;
        position: absolute;
        width: 45px;
        height: 45px;
        top: 35px;
        left: 35px;
        @media (max-width: 768px){
         background-image: url(${icoBraceF});
         top: -25px;
         left: -25px;
         background-color: #fc4c4c;
         background-size: 25px 25px;
         background-repeat: no-repeat;
         border-radius: 50%;
        }
      }
      .--main {
        font-size: 20px;
        font-weight: 300;
        line-height: 2;
        color: #000000;
        width: 90%;
        margin-bottom: 20px;
        @media (max-width: 768px){ 
          font-size: 14px;
          line-height: 1.4;
          text-align: center;  
          width: 100%;
       }
      }
    }
    .--sub-combo {
      display: none;
      @media (max-width: 768px){
        display: block;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
      }
    }
    .--sub-name, .--sub-text {
      text-align: right;
      font-weight: 600;
      padding-right: 5%;
      @media (max-width: 768px){
        display: none;
      }
    }
    .--sub-name {
      font-size: 18px;
    }
    .--sub-text {
      font-size: 16px;
      font-weight: 700;
    }
  }
`
