import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../theme'
import Link from './Link'
import Arrow from '../assets/arrow.svg'

const ArtisanTile = ({
  title,
  subTitle,
  name,
  type,
  picture,
  link,
  descriptionTitle,
  description,
  ...props
}) => (
  <Link to={link} target="_blank" rel="noopener noreferrer">
    <StyledArtisanTile {...props}>
      <div className="artisan-tile-container">
        <div className="caption">
          {title && <p className="title">{title}</p>}
          {subTitle && <p className="subtitle">{subTitle}</p>}
          <div className="artisan-title">
            <p className="name">{name}</p>
            <div className="separator"></div>
            <p className="type">{type}</p>
          </div>
          <p className="description-title">{descriptionTitle}</p>
          <p className="description">{description?.description}</p>
          {/* <div className="see-more"> // DESACTIVATION POUR SIMPLIFICATION
            <span className="next">Lire la suite</span>
            <span className="arrow">
              <img src={Arrow} alt="arrow" />
            </span>
          </div> */}
        </div>
        <div className="picture">
          <Img
            fluid={picture.fluid}
            alt={picture.title}
            style={imgStyle}
            loading="lazy"
          />
        </div>
      </div>
    </StyledArtisanTile>
  </Link>
)

const imgStyle = {
  width: '100%',
  height: '100%',
  zIndex: '-1',
}

const StyledArtisanTile = styled.figure`
  .artisan-tile-container {
    position: relative;
  }

  .picture {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: calc(5 / 12 * 100%);
    overflow: hidden;
    align-self: center;
    height: 100%;
  }

  .caption {
    width: 67%;
    background-color: ${colors.brand.inverted};
    clip-path: polygon(0% 0%, 100% 0%, 65% 100%, 0 100%);
    padding: 90px 67px 60px;

    .title {
      font-family: Bely;
      color: ${colors.brand.green};
      font-size: 47px;
    }

    .subtitle {
      font-size: 21px;
    }

    .artisan-title {
      display: flex;
      align-items: center;
      margin-top: 100px;
      margin-bottom: 43px;

      .name {
        font-family: Bely;
        font-size: 22px;
        color: ${colors.brand.green};
        margin-right: 6px;
      }

      .separator {
        width: 16px;
        height: 20px;
        clip-path: polygon(75% 0%, 100% 0%, 25% 100%, 0% 100%);
        background-color: ${colors.brand.normal};
        margin-right: 11px;
      }

      .type {
        font-size: 21px;
      }
    }

    .description-title {
      font-family: Centra Bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .description {
      height: 160px;
      max-width: 607px;
      font-size: 18px;
      margin-bottom: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }

    .see-more {
      margin-top: auto;
      display: flex;
      color: ${colors.brand.black};

      .next {
        margin-right: 20px;
        transition: color 150ms ease-in-out;
      }

      .arrow {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          transform: rotate(180deg);
          max-width: 20px;
        }
      }
    }
  }

  &:hover {
    .caption {
      .see-more {
        .next {
          color: ${colors.brand.normal};
        }
      }
    }
  }

  @media (max-height: 810px) and (min-width: ${breakpoints.desktop}px) {
    .caption {
      padding: 50px;

      .subtitle {
        margin-bottom: 45px;
      }

      .artisan-title {
        margin-top: 60px;
      }
    }
  }

  @media (max-width: 1500px) {
    .caption {
      width: 75%;
      clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0 100%);
    }
    .picture {
      // .gatsby-image-wrapper {
      //   position: absolute;
      //   right: -25%;
      // }
    }
  }

  @media (max-width: 1200px) {
    .caption {
      width: 80%;
      clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 0 100%);
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .artisan-tile-container {
      display: flex;
      flex-direction: column-reverse;
    }

    .title,
    .subtitle {
      display: none;
    }
    .picture {
      position: unset;
      height: 300px;
      width: 100%;
      overflow: hidden;
      align-self: center;

      // .gatsby-image-wrapper {
      //   right: unset;
      // }
    }
    .caption {
      width: 100%;
      clip-path: unset;
      padding: 36px;

      .artisan-title {
        margin-top: unset;
        margin-bottom: 30px;

        .name {
          font-size: 15px;
        }
        .type {
          font-size: 16px;
        }
      }

      .description {
        max-height: 145px;
        font-size: 14px;
        -webkit-line-clamp: 8;
      }

      .see-more {
        .next {
          margin-left: auto;
        }
      }
    }
  }
`

export default ArtisanTile
export { StyledArtisanTile }

export const ArtisanFragment = graphql`
  fragment ArtisanFragment on ContentfulArtisan {
    id
    name
    link
    type
    description {
      description
    }
    descriptionTitle
    picture {
      title
      fluid(maxHeight: 600, maxWidth: 700, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`
